import React from 'react';
import './text.scss';

export const Title = ({ children, Icon, className = '', ...props }) => {
  return (
    <div className={`title ${className}`} {...props}>
        {Icon&&<Icon className="icon"/>}
        {children}
    </div>
  );
};

export const Text = ({ type, children, className = '', ...props }) => {
    const Element = type;
    return (
      <Element className={`${type} ${className}`} {...props}>
        {children}
      </Element>
    );
  };
  
export default Text;

