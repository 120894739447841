import React, {useEffect, useState, useMemo} from "react";
import {useQuery, useMutation, useQueryClient} from 'react-query'
import { Oval } from  'react-loader-spinner'
import Icon from '@mui/material/Icon';

import Button from '../../componentes/button/Button'
import PopUpSolicitaEntrega from "../../componentes/popUp/PopUpSolicitaEntrega";
import PopUpAtualizaColeta from "../../componentes/popUp/popUpAtualizaColeta/PopUpAtualizaColeta";
import PopUp from "../../componentes/popUpVazio/PopUpVazio"
import Tabela from "../../componentes/table/tabela/tabela";
import Layout from "../../componentes/layout";
import Cores from '../../componentes/cores/Cores';
import FormFieldList from '../../componentes/formField/formFieldList/FormFieldList';
import Select from '../../componentes/select/Select'
import DateModel from '../../componentes/date/Date'
import Lista from '../../componentes/list/listaItens/Lista';

import {procuraFiltroData, procuraPorFiltro, deletaPorId } from '../../api/grandesEncomendasData'

import "./grandesEncomendas.scss"

const GrandesEncomendas = () => {

  const queryClient = useQueryClient()

  const cabecaTabela = [
    { value: 'createdAt', label: 'HORA', tipo: {"data":"dia/mes/ano"}},
    { value: 'status', label: 'STATUS', tipo: {"destaque": {"Aguardando": "vermelho", "Finalizada":"verde", "Coletado":"laranja" }} },
    { value: '_id', label: 'ID', tipo: {"id":"id"}},
    { value: '[cliente_remetente.nome_cliente_remetente]', label: 'SOLICITANTE', tipo: {"padrao":"padrao"}},
    { value: '[cliente_destinatario.endereco_parada.cidade_parada]', label: 'ENDEREÇO', tipo: {"padrao":"padrao"}},
    { value: 'id_entregador', label: 'ENTREGADOR', tipo: {"padrao":"padrao"}},
  ]

  const opcoesSeletor = [
    { value: 'Finalizada', label: 'Finalizada' },
    { value: 'Coletado', label: 'Coletado' },
    { value: 'Aguardando', label: 'Aguardando' },
  ]

  let PageSize = 10;

  const id = JSON.parse(localStorage.getItem("usuarioCash"))[0]["id"]

  const [buttonPopUpColeta, setButtonPopUpColeta] = useState(false);

  const [loader, setLoader] = useState(false);

  const [linhaTabela, setLinhaTabela] = useState([]);
  const [dataTabela, setDataTabela] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [popUpFiltro, setPopUpFiltro] = useState(false);
  const [statusTogger, setStatusTogger] = useState("toggle_on");

  const [popUpCancel, setPopUpCancel] = useState(false);
  const [popUpEdit, setPopUpEdit] = useState(false);
  const [atualizaColeta, setAtualizaColeta] = useState(false);

  const [inputFields, setInputField] = useState([
    { label: 'ID:', name: '_id', placeholder: 'Digite aqui o id do pedido', value: '',  tipo: 'texto' },
    { label: 'Nome da empresa:', name: 'cliente_remetente.nome_cliente_remetente', placeholder: 'Digite aqui o nome da empresa', value: '',  tipo: 'texto'},
    { label: 'Rua destino:', name: 'cliente_destinatario.endereco_parada', placeholder: 'Digite aqui a rua e/ou numero de destino', value: '',  tipo: 'texto'},
    { label: 'Cidade destino:', name: 'cliente_destinatario.cidade_parada', placeholder: 'Digite aqui a cidade de destino', value: '',  tipo: 'texto'},  ]);

  const cabecaLista = [
    { value: '_id', label: 'ID', tipo: {"id":"id"}},
    { value: 'status', label: 'STATUS', tipo: {"destaque": {"Aguardando": "vermelho", "Finalizada":"verde", "Coletado":"laranja" }} },
    { value: 'createdAt', label: 'Horário solicitação',  tipo: {"data":"dia/mes/ano"}},
    { value: '[cliente_remetente.nome_cliente_remetente]', label: 'Origem', tipo: {"padrao":"padrao"}},
    { value: '[cliente_destinatario.endereco_parada.cidade_parada]', label: 'Destino', tipo: {"padrao":"padrao"}},
    { value: 'volume', label: 'Volume',  tipo: {"padrao":"padrao"}},
    { value: 'imagem_coleta', label: 'Imagem coleta', tipo: {"icone": "camera_alt"} },
    { value: 'imagem_entrega', label: 'Imagem entrega', tipo: {"icone":  "camera_alt"} },
    { value: '[dados_recebedor.nome]', label: 'Nome recebedor', tipo: {"padrao":"padrao"} },
    { value: '[dados_recebedor.cpf]', label: 'CPF recebedor', tipo: {"padrao":"padrao"} },
    { value: 'assinatura_destinatario', label: 'Assinatura', tipo: {"icone":  "draw"} },
    { value: 'link', label: 'Link de rastreio', tipo: {"link-especial":  "draw"} },
    { value: 'obs_reenvio', label: 'Observações', tipo: {"icone": "description"} },
  ]

  const [selectFilter, setSelectFilter] = useState('');

  const [checkData, setcheckData] = useState(false);

  const [week, setWeeks] = useState({})

  const [popUpLinha, setPopUpLinha] = useState(false);

  const dataAgora = useMemo(() => new Date(), []);

  const dataHoje = useMemo(() => {
    const date = new Date(dataAgora);
    date.setDate(dataAgora.getDate() + 2);
    return date;
  }, [dataAgora]);

  const date30DaysAgo = useMemo(() => {
    const date = new Date(dataHoje);
    date.setDate(dataHoje.getDate() - 3);
    return date;
  }, [dataHoje]);

  const defaultQueryKey = useMemo(() => [{ updatedAt: { $gte: date30DaysAgo, $lt: dataHoje }, "cliente_remetente.id_da_empresa": JSON.parse(localStorage.getItem("usuarioCash"))[0]["id"] }], [date30DaysAgo, dataHoje]);
  const data30Dias = useQuery(defaultQueryKey, procuraFiltroData, {refetchInterval: false});
  
  const lidarTogger = async () => {
    const status = statusTogger === "toggle_on" ? "toggle_off" : "toggle_on"
    setStatusTogger(status)
  }

  const lidarFiltro = async () => { 
    var filtroDatas = {}
    setLoader(true)

    inputFields.map((obj) =>{
      if(obj.value !== ''){
        filtroDatas[obj.name] = { $regex: obj.value, $options: 'i' }
      }
    })

    if(selectFilter !== ''){
      filtroDatas["status"] = selectFilter
    }

    if(checkData){
      filtroDatas["createdAt"] =  { $gte: week.startDate, $lt: week.endDate }
    }

    filtroDatas["cliente_remetente.id_da_empresa"] = JSON.parse(localStorage.getItem("usuarioCash"))[0]["id"]

    //console.log(filtroDatas)
    setStatusTogger("toggle_off")
    procuraPorFiltroData.mutate({queryKey:[filtroDatas]})

  }

  const lidarCancel = async () => { 
    setPopUpCancel(false)
    setLoader(true)

    var id = linhaTabela["_id"]

    deleteById.mutate({_id: id})

  }

  const deleteById =  useMutation(deletaPorId, {
    onSuccess: (e) => {
      if(e["status"] == '201'){
        setLoader(false)
        setPopUpLinha(false)
        queryClient.invalidateQueries(); 
        
      }
    },
    onError: (e) => {
      setLoader(false)
      setPopUpCancel(false)
      setPopUpLinha(false)
      alert(e.response.data)
    }
  });


  const procuraPorFiltroData =  useMutation(procuraPorFiltro, {
    onSuccess: (e) => {
      if(e["status"] == '201'){
        //console.log(e.data)

        setInputField(prevFields =>
          prevFields.map(field => ({ ...field, value: "" }))
        );
        setSelectFilter("")
        setLoader(false)
        setDataTabela(e.data.data)
        setPopUpFiltro(false)
        queryClient.invalidateQueries(); 
        
      }
    },
    onError: (e) => {

      setInputField(prevFields =>
        prevFields.map(field => ({ ...field, value: "" }))
      );
      setSelectFilter("")
      setLoader(false)
      alert(e.response.data)
      setPopUpFiltro(false)
    }
  });

  useEffect(() => {

    if (data30Dias.isSuccess && statusTogger === "toggle_on") {
      setDataTabela(data30Dias.data.data.data)
    }
  },[ data30Dias.data ])

  useEffect(() => {
    let interval;
    if (statusTogger === "toggle_on") {

      interval = setInterval(() => {
        data30Dias.refetch();
      }, 30000);
    }
    return () => clearInterval(interval);
  }, [statusTogger, data30Dias]);

  useEffect(() => {
    if(linhaTabela.length !== 0){
      setPopUpLinha(true)
      //console.log(linhaTabela)
    }

  }, [linhaTabela]);

  useEffect(() => {
    if(linhaTabela === false){
      setLinhaTabela([])
      //console.log(linhaTabela)
    }

  }, [popUpLinha]);

  useEffect(() => {
    if(atualizaColeta === true){
      setPopUpLinha(false)
      setAtualizaColeta(false)
    }

  }, [atualizaColeta]);
  
    return (
        <div className="main">
          <div className="tela-principal-grandes-encomendas">
            { loader == true &&
              <div className='loader'>
                  <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
              </div>
            }

            <div className="titulo-grandes-entregas">
              <Icon style={{ fontSize:"30px", marginRight:"5px"}}> local_shipping </Icon>
              <div className="texto-titulo-Grandes-encomendas">
                Grandes Encomendas
              </div>
            </div>

            <div className="botao-coleta">
                <Button onClick={() => {setButtonPopUpColeta(true)}} width={"130px"} color={"#FEFCFF"} background={'#6B4380'} border={'1px solid #6B4380'} text={"Solicitar Entrega"}/>
            </div>

            <div className='botoes-filtro'>
              <Button onClick={()=>{setPopUpFiltro(true)}} width={"70px"} color={"#ffffff"} background={Cores.roxo} border={Cores.roxo} text={"Filtro"} />
              <div className='botao-atualiza'>
                Atualização automática
                <Icon className={statusTogger} onClick={()=>{lidarTogger()}}> {statusTogger}</Icon>
              </div>
            </div>

            <div className="menu-status-entregas" >
              {<Tabela onClickLinha={setLinhaTabela} name={"table"} value= {"value"} data={dataTabela} head={cabecaTabela} onPageChange={page => setCurrentPage(page)} totalCount={dataTabela.length} currentPage={currentPage} pageSize={PageSize} />}
            </div>
            
          </div>

          <PopUpSolicitaEntrega trigger={buttonPopUpColeta} setTrigger={setButtonPopUpColeta} setLoader={setLoader} id={id} />

          <PopUp trigger={popUpFiltro} setTrigger={setPopUpFiltro}>
            <div className='popUp-filtro-cargo'>
              { loader == true &&
                <div className='loader'>
                  <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
                </div>
              }

              <div className='title-filter'>Filtro</div>

                <div className='itens-filtro'>
                  <div className='formulario-filtro'>
                    <FormFieldList inputFields={inputFields} setInputField={setInputField} />
                  </div>

                  <div className='select-cargo'>
                    <Select name={"selectFilter"} value={selectFilter} onChange={setSelectFilter} options={opcoesSeletor} titulo={"Status"}/>
                  </div>

                  <div className='filtro-data'>  
                    <input
                      className='box' 
                      type="checkbox"
                      onChange={(e) =>  setcheckData(e.target.checked)}
                      style={{ marginRight: "2px" }}
                    />
                    <label>
                      Data Pedido:
                        {checkData&&
                          <DateModel
                          onDateChange={setWeeks}
                          />
                        }
                    </label>
                  </div>

                  <div className='botao-filtro-cargo'>
                    <Button onClick={()=>{lidarFiltro()}} width={"100px"} color={"#ffffff"} background={Cores.verde} border={Cores.verde} text={"Aplicar"} />
                  </div>
              </div>
            </div>
          </PopUp>

          <PopUp trigger={popUpLinha} setTrigger={setPopUpLinha}>
            <div className='popUp-infos-linha'>
              { loader == true &&
                <div className='loader'>
                    <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
                </div>
              }
              { linhaTabela.length !== 0 && 
                <div>
                  <div className='titulo-entrega'> Entrega</div>
                  { linhaTabela["status"] === "Aguardando" &&
                    <div style={{display:"flex", justifyContent:"flex-end"}}>
                      <Icon style={{ fontSize:"20px", marginRight:"5px", color: Cores.cinzaEscuro, cursor:"pointer"}} onClick={() => {setPopUpEdit(true)}}> edit </Icon>
                      <Icon style={{ fontSize:"20px", marginRight:"5px", color: Cores.vermelho, cursor:"pointer"}} onClick={() => {setPopUpCancel(true)}} > cancel </Icon>
                    </div>
                  }
                  <Lista chave={cabecaLista} data={linhaTabela} />
                </div>
              }
            </div>
         </PopUp>

         <PopUp trigger={popUpCancel} setTrigger={setPopUpCancel}>
            <div className='popUp-cancel'>
              <div className='texto-cancel'>
                Deseja cancelar esta coleta?
              </div>
              <div className='botoes-cancel'>
                <Button onClick={()=>{lidarCancel()}} width={"70px"} color={"#ffffff"} background={Cores.verde} border={Cores.verde} text={"Sim"} />
                <Button onClick={()=>{setPopUpCancel(false)}} width={"70px"} color={"#ffffff"} background={Cores.vermelho} border={Cores.vermelho} text={"Não"} />
              </div>
            </div>
         </PopUp>

         {linhaTabela.length !== 0 &&
          <PopUpAtualizaColeta atualiza={setAtualizaColeta} trigger={popUpEdit} setTrigger={setPopUpEdit} setLoader={setLoader} obj={linhaTabela} />
         }

        </div>
    );
  };

  const PaginaGrandesEncomendas = () => {

    return <>
      <Layout>
        <GrandesEncomendas />
      </Layout>
    
    </>
  
  }
  
  
  export default PaginaGrandesEncomendas;