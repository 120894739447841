import React, { useEffect, useRef } from 'react';
import { iniciaAutocomplete } from './autocomplete.ts';
import '../mapa.scss'
import '../../input/input.scss';

const Autocompleta = ({setaEndereco,inputValor, style}) => {
  const inputRef = useRef(null);
  const inicializaRef = useRef(false); 

  useEffect(() => {
    if (inputRef.current && !inicializaRef.current) {
      inputRef.current.value = inputValor; 
      inicializaRef.current = true; 
    }
    iniciaAutocomplete(setaEndereco); 
  }, [setaEndereco]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = inputValor; 
    }
  }, [inputValor]);

  return (
    <div id="pac-container" className="input" style={style}>
      <input 
        id="pac-input"
        type="text" 
        placeholder="Digite o endereço" 
        className="placeholder"
        ref={inputRef}
        width={"400px"}
      />
    </div>
  );
};

export default Autocompleta;
