import React from "react";
import "./menuLateral.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { Icon } from '@mui/material';

const MenuLateral = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const menuItems = [
        { path: "/home", icon: "local_shipping", label: "Grandes Encomendas", deactivate: false },
        { path: "/retornos", icon: "rotate_left", label: "Controle de Retornos", deactivate: false },
        { path: "/home", icon: "payments", label: "Gestão Financeira", deactivate: true },
        { path: "/taonPay", icon: "store", label: "Convênio Taon", deactivate: false  },
    ];

    return (
        <div className="tela-lateral">
            {menuItems.map((item, index) => (
                <div 
                    key={index} 
                    className="bloco-clicavel" 
                    onClick={() => navigate(item.path)}
                >
                    <div className="bloco-icone">
                        <Icon 
                            style={location.pathname === item.path && !item.deactivate ? { } : { color: "#BCB0B0" }} 
                            className="icon-update"
                        >
                            {item.icon}
                        </Icon>
                    </div>
                    <div 
                        className="titulo" 
                        style={location.pathname === item.path && !item.deactivate ? { } : { color: "#BCB0B0" }}
                    >
                        {item.label}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MenuLateral;
