import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { TableCell, TableRow } from '@mui/material';

import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import ReplyAllOutlinedIcon from '@mui/icons-material/ReplyAllOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import ReportIcon from '@mui/icons-material/Report';

import Layout from '../../componentes/layout';
import TabPanel from '../../componentes/tabPanel/TabPanel';
import Input from '../../componentes/input/Input';
import PopUpRetornosMotorista from '../../componentes/popUpVazio/PopUpRetornosMotorista';
import PopUpSolicitaRetorno from '../../componentes/popUp/PopUpSolicitaRetorno';
import PopUpProcuraRetornos from '../../componentes/popUp/PopUpProcuraRetornos';
import PopUpAlerta from '../../componentes/popUp/PopUp'
import PopUpConfirmacao from '../../componentes/popUpVazio/PopUpVazio';
import { AddButton, WorldSearchButton, Button, PhoneButton, CopyButton } from '../../componentes/button/Button'

import { getRetornosDaEmpresa, atulizaRetornoMachine, atualizaStatusRetorno, enviaMsgUrgenteMotorista } from '../../api/retornos';
import { getSolicitacaoByID, getSolicitacaoByEmpresa } from '../../api/retornos'
import "./retornos.scss"

const Retornos = () => {

  const [value, setValue] = useState(0);
  const [os, setOs] = useState('')
  const [solicitacaoRetorno, setSolicitacaoRetorno] = useState([])
  const [solicitacoesPesquisa, setSolicitacoesPesquisa] = useState([])
  const [retornosAbertos, setRetornosAbertos] = useState([]);
  const [retornosRetornados, setRetornosRetornados] = useState([]);
  const [popUpTrigger, setPopUpTrigger] = useState(false)
  const [conteudoPopUp, setConteudoPopUp] = useState("");
  const [popUpAlerta, setPopUpAlerta] = useState(false);
  const [popUpConfirmacao, setPopUpConfirmacao] = useState(false);

  const [popUpProcuraRetornos, setPopUpProcuraRetornos] = useState(false);


  const idEmpresa = JSON.parse(localStorage.getItem("usuarioCash"))[0]["id"]

  const [mostrarHoje, setMostrarHoje] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [pageRetornados, setPageRetornados] = React.useState(0);
  const [rowsPerPageRetornados, setRowsPerPageRetornados] = React.useState(10);

  const [popUpMotorista, setPopUpMotorista] = useState(false);
  const [conteudoPopUpMotorista, setConteudoPopUpMotorista] = useState("");

  const [state, setState] = useState({
    maquininha: false,
    dinheiro: false,
    comandaAssinada: false,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        await atualizaRetornos(idEmpresa, mostrarHoje, setRetornosAbertos, setRetornosRetornados);
      } catch (error) {
        console.error("Erro ao atualizar retornos no useEffect: ", error);
      }
    }

    fetchData();
  }, [idEmpresa, mostrarHoje]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageRetornados = (event, newPage) => {
    setPageRetornados(newPage)
  }

  const handleChangeRowsPerPage = (
    event
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStateChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const onAddRetorno = async () => {
    try {
      const solicitacao = await fetchSolicitacaoByID(os);
      setSolicitacaoRetorno(solicitacao)

      if (solicitacao && solicitacao.paradas && solicitacao.paradas.length > 0) {
        setPopUpTrigger(!popUpTrigger);
      } else {
        setConteudoPopUp("OS não identificado");
        setPopUpAlerta(true);
      }
    } catch (error) {
      console.error("Erro ao adicionar retorno: ", error);
    }
  };

  const onSearchRetornos = async () => {
    try {
      const solicitacoes = await fetchSolicitacaoByEmpresa();


      const updatedSolicitacoes = solicitacoes.map((solicitacao) => {
        const updatedParadas = solicitacao.paradas.filter((parada) => {
          return !Object.values(retornosAbertos).some((retornos) =>
            retornos.some((retorno) => retorno?.retorno?.numero_pedido === parada?.numero_pedido)
          );
        });

        if (updatedParadas.length === 0) return null;
        return { ...solicitacao, paradas: updatedParadas };
      }).filter(Boolean);

      setSolicitacoesPesquisa(updatedSolicitacoes);

      if (updatedSolicitacoes.length > 0) {
        setPopUpProcuraRetornos(!popUpProcuraRetornos);
      } else {
        setConteudoPopUp("Não foram encontrados novos retornos!");
        setPopUpAlerta(true);
      }
    } catch (error) {
      console.error("Erro ao adicionar retorno: ", error);
    }
  };

  const fetchSolicitacaoByEmpresa = async () => {
    try {
      const response = await getSolicitacaoByEmpresa(idEmpresa);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar solicitacao pela OS: ', error);
    }
  }

  const fetchSolicitacaoByID = async (id) => {
    try {
      const response = await getSolicitacaoByID(id);
      return response.data.response[0];
    } catch (error) {
      console.error('Erro ao buscar solicitacao pela OS: ', error);
    }
  };

  const atualizaRetornosFromMachine = async () => {
    try {
      const novosRetornosAbertos = await Promise.all(
        Object.values(retornosAbertos).map((retornosMotorista, index) => {
          retornosMotorista.filter(retorno =>
            !retorno.retorno.finalizado ||
            !retorno.retorno.motorista ||
            !retorno.retorno.telefone_motorista ||
            !retorno.retorno.nomeEmpresa||
            !retorno.retorno.idMotorista
          )
            .map(async (retorno) => {
              const novoRetorno = await atulizaRetornoMachine(retorno.retorno._id, retorno.retorno.os);
            })
        }
        ));
      setRetornosAbertos([])
    } catch (error) {
      console.error(`Erro ao atualizar retornos em aberto: ${error} `);
    }

    try {
      const novosRetornosRetornados = await Promise.all(
        retornosRetornados.filter(retorno =>
          !retorno.finalizado ||
          !retorno.motorista ||
          !retorno.telefone_motorista ||
          !retorno.nomeEmpresa
        )
          .map(async (retorno) => {
            const novoRetorno = await atulizaRetornoMachine(retorno._id, retorno.os);
          })
      );
      setRetornosRetornados([])
    } catch (error) {
      console.error(`Erro ao atualizar retornos em retornados: ${error} `);
    }

    try {
      atualizaRetornos(idEmpresa, mostrarHoje, setRetornosAbertos, setRetornosRetornados)
    } catch (error) {
      console.error(`Erro ao atualizar retornos nas variáveis `, error)
    }
  };

  function selecionaMotoristasRetornoUrgente() {
    const motoristasNotificados = new Set();
  
    for (const motorista in retornosAbertos) {
      if (retornosAbertos.hasOwnProperty(motorista)) {
        const retornos = retornosAbertos[motorista];
  
        for (const retorno of retornos) {
          if (!motoristasNotificados.has(motorista)) {
            if (
              (state.comandaAssinada && retorno.retorno.retorno.comandaAssinada) ||
              (state.dinheiro && retorno.retorno.retorno.dinheiro) ||
              (state.maquininha && retorno.retorno.retorno.maquininha)
            ) {
              motoristasNotificados.add(motorista);
              enviaMsgUrgenteMotorista(retorno.retorno);
            }
          }
        }
      }
    }

    setState({
      maquininha: false,
      dinheiro: false,
      comandaAssinada: false
    });

    setPopUpConfirmacao(false);

  }
  

  return (
    <div className="main">
      <div className="tela-principal-retornos">
        <div className="titulo-grandes-entregas">
          <div className="texto-titulo-Grandes-encomendas">
            <AssignmentReturnIcon style={{ width: '30px', height: 'auto', color: '#6B4380' }} />
            Controle de Retornos
          </div>

          <div className="tabs-container">
            <Tabs value={value} onChange={handleChange} aria-label="Retornos">
              <Tab label="Abertos" />
              <Tab label="Retornados" />
            </Tabs>
          </div>

        </div>

        <div className='abraca-conteudo-interno'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {value == 0 ?
              <>
                <Input
                  name='os'
                  value={os}
                  onChange={(e) => setOs(e)}
                  placeholder={'Número OS'}
                  className="placeholder"
                  margin="0px"
                  width={"280px"}
                  height={"23px"}
                />
                <AddButton onClick={onAddRetorno} />

                <div style={{ display: 'flex', height: '37px', alignItems: 'center', margin: '0 8px' }}> ou </div>

                <WorldSearchButton onClick={onSearchRetornos} />

              </>

              :
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
                <Checkbox
                  type="checkbox"
                  name="dinheiro"
                  checked={mostrarHoje}
                  onChange={() => setMostrarHoje(!mostrarHoje)}
                />
                Mostrar somente hoje
              </div>
            }

            <div style={{ marginLeft: 'auto' }}>
              <Button className="danger" onClick={()=>setPopUpConfirmacao(true)}>
                <ReportIcon />
              </Button>

              <Button onClick={atualizaRetornosFromMachine}>
                <CachedIcon />
              </Button>
            </div>
          </div>
        </div>


        {retornosAbertos.length !== 0 &&
          <div className='abraca-conteudo-interno'>
            <TabPanel
              value={value}
              index={0}
              rowCount={Object.keys(retornosAbertos).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            >

              {Object.values(retornosAbertos)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((retornoMotorista, index) => (
                <LinhaTabela
                  key={index}
                  retorno={retornoMotorista}
                  setPopUpMotorista={setPopUpMotorista}
                  setConteudoPopUpMotorista={setConteudoPopUpMotorista}
                />

              ))}
            </TabPanel>
          </div>
        }

        {retornosRetornados.length !== 0 &&
          <div className='abraca-conteudo-interno'>
            <TabPanel
              value={value}
              index={1}
              rowCount={Object.keys(retornosRetornados).length}
              rowsPerPage={rowsPerPage}
              page={pageRetornados}
              onPageChange={handleChangePageRetornados}
              onRowsPerPageChange={handleChangeRowsPerPage}
              agrupaPorMotorista={false}
            >
              {retornosRetornados?.slice(pageRetornados * rowsPerPage, pageRetornados * rowsPerPage + rowsPerPage).map((retorno, index) => (
                <LinhaTabela
                  key={index}
                  retorno={retorno}
                  setRetornosAbertos={setRetornosAbertos}
                  setRetornosRetornados={setRetornosRetornados}
                  agrupaPorMotorista={false}
                  atualizaRetornos={()=>atualizaRetornos(idEmpresa, mostrarHoje, setRetornosAbertos, setRetornosRetornados)}
                />
              ))}
            </TabPanel>
          </div>
        }

        <PopUpSolicitaRetorno os={os} setOs={setOs} trigger={popUpTrigger} setTrigger={setPopUpTrigger} solicitacao={solicitacaoRetorno} atualizaRetornos={() => atualizaRetornos(idEmpresa, mostrarHoje, setRetornosAbertos, setRetornosRetornados)} />

        <PopUpProcuraRetornos trigger={popUpProcuraRetornos} setTrigger={setPopUpProcuraRetornos} retornos={solicitacoesPesquisa} atualizaRetornos={() => atualizaRetornos(idEmpresa, mostrarHoje, setRetornosAbertos, setRetornosRetornados)} />

        <PopUpAlerta trigger={popUpAlerta} setTrigger={setPopUpAlerta}>
          {
            <div>
              {conteudoPopUp}
            </div>
          }
        </PopUpAlerta>

        <PopUpRetornosMotorista
          trigger={popUpMotorista}
          setTrigger={setPopUpMotorista}
          retornos={conteudoPopUpMotorista}
          atualizaRetornos={() => atualizaRetornos(idEmpresa, mostrarHoje, setRetornosAbertos, setRetornosRetornados)}
        />

        <PopUpConfirmacao trigger={popUpConfirmacao} setTrigger={setPopUpConfirmacao} light={true}>
           
          {
            <div style={{ padding: "15px" }}>
              <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                {"Enviaremos mensagem para todos os motoristas que tem retorno de seu estabelecimento."}<br />
                {"Por favor, confirme qual tipo de retorno você quer sinalizar:"}
              </div>
              <div style={{width: 'fit-content', margin: 'auto', paddingLeft: "15px" }}>
                <FormGroup row={true}>
                  <FormControlLabel disableTypography={true} control={<Checkbox checked={state.dinheiro} onChange={handleStateChange} name="dinheiro" />} label="Dinheiro" />
                  <FormControlLabel disableTypography={true} control={<Checkbox checked={state.maquininha} onChange={handleStateChange} name="maquininha" />} label="Maquininha" />
                  <FormControlLabel disableTypography={true} control={<Checkbox checked={state.comandaAssinada} onChange={handleStateChange} name="comandaAssinada" />} label="Comanda Assinada" />
                </FormGroup>
              </div>
              <div style={{width: 'fit-content', margin: 'auto', marginTop: '20px'}}>
                < Button
                  text='Sim'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='rgb(66, 175, 60)'
                  color='white'
                  onClick={selecionaMotoristasRetornoUrgente}
                />
                < Button
                  text='Não'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='#c84333'
                  color='white'
                  onClick={() => { setPopUpConfirmacao(false) }}
                />
              </div>

            </div>
          }
        </PopUpConfirmacao>

      </div>
    </div>
  );
};




const LinhaTabela = ({ retorno, setPopUpMotorista, setConteudoPopUpMotorista, agrupaPorMotorista = true, atualizaRetornos=() => {} }) => {
  const [popUpConfirmacao, setPopUpConfirmacao] = useState(false);

  function handleVisualizaMotorista() {
    setPopUpMotorista(true)
    setConteudoPopUpMotorista(retorno);
  }

  function contaRetornos() {
    return retorno.length;
  }

  function encontraDataMaisAntiga() {
    let oldestDate = new Date('9999-12-31T23:59:59.999Z');
    const specificDate = new Date('2020-01-01T00:00:00.000Z');
    const currentDate = new Date();


    retorno.forEach(retornoObj => {
      if (retornoObj.retorno.finalizado) {
        const finalizadoDate = new Date(retornoObj.retorno.finalizado);

        if (finalizadoDate && finalizadoDate < oldestDate && finalizadoDate > specificDate) {
          oldestDate = finalizadoDate;
        }
      }


    });

    if (!oldestDate || oldestDate > currentDate) {
      return "-";
    }

    const date = new Date(oldestDate).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }) + " " +
      new Date(oldestDate).toLocaleTimeString('pt-BR')

    return date;
  }

  async function onSetAguardando(id, setPopUpConfirmacao) {
    await atualizaStatusRetorno(id, "restaurante", "Aguardando");
    atualizaRetornos();
    setPopUpConfirmacao(false);
  }

  const ReabrirRetornoButton = ({ id }) => {
    return (
      <>
        <Button onClick={() => setPopUpConfirmacao(true)} border='0px' background='none'>
          <ReplyAllOutlinedIcon />
        </Button>
        <PopUpConfirmacao trigger={popUpConfirmacao} setTrigger={setPopUpConfirmacao} light={true}>
          {
            <div style={{ padding: "15px" }}>
              <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                {"Você deseja reabrir este retorno?"}
              </div>
              <div>
                < Button
                  text='Sim'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='rgb(66, 175, 60)'
                  color='white'
                  onClick={() => { onSetAguardando(id, setPopUpConfirmacao) }}
                />
                < Button
                  text='Não'
                  width='120px'
                  borderRadius='8px'
                  border='black'
                  background='#c84333'
                  color='white'
                  onClick={() => { setPopUpConfirmacao(false) }}
                />
              </div>

            </div>
          }
        </PopUpConfirmacao>
      </>
    )
  }

  return (<>
    {agrupaPorMotorista ?
      <TableRow key={retorno[0].retorno.motorista}>
        <TableCell className='marieta'>{retorno[0].retorno.motorista}</TableCell>
        <TableCell className='marieta'> <PhoneButton link={retorno[0].retorno.telefone_motorista} /></TableCell>
        <TableCell className='marieta' align='center'>{contaRetornos()}</TableCell>
        <TableCell className='marieta'>{encontraDataMaisAntiga()}</TableCell>
        <TableCell className='marieta'>
          < Button
            text='Visualizar'
            width='120px'
            borderRadius='0px'
            border='black'
            background='green'
            color='white'
            onClick={handleVisualizaMotorista}
          />
        </TableCell>

      </TableRow>
      :
      <TableRow className='table-title'>
        <TableCell>{retorno.os} <CopyButton link={retorno.os} /></TableCell>
        <TableCell className='marieta'>{retorno.numero_pedido} </TableCell>
        <TableCell className='marieta'>{retorno.numeroControle} </TableCell>
        <TableCell className='marieta'>{retorno.parada}</TableCell>
        <TableCell className='marieta'>{retorno.motorista}</TableCell>
        <TableCell className='marieta'> <PhoneButton link={retorno.telefone_motorista} /></TableCell>
        <TableCell className='marieta' align='center'>
          {retorno.finalizado ?
            <>
              {
                new Date(retorno.finalizado).toLocaleDateString('pt-BR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })} {new Date(retorno.finalizado).toLocaleTimeString('pt-BR')}
            </>
            : "-"
          }
        </TableCell>
        <TableCell className='marieta' align='center'>
          {retorno.hora_retorno ?
            <>
              {
                new Date(retorno.hora_retorno).toLocaleDateString('pt-BR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })} {new Date(retorno.hora_retorno).toLocaleTimeString('pt-BR')}
            </>
            : "-"
          }
        </TableCell>
        <TableCell>
          {retorno.retorno.dinheiro ?
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LocalAtmOutlinedIcon style={{ marginRight: '5px' }} /> R${retorno.retorno.valorDinheiro}
            </div>
            : null}
          {retorno.retorno.maquininha ?
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CreditCardOutlinedIcon style={{ marginRight: '5px' }} /> R${retorno.retorno.valorMaquininha} ID: {retorno.retorno.idMaquininha}
            </div>
            : null}
          {retorno.retorno.comandaAssinada ?
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ReceiptLongOutlinedIcon style={{ marginRight: '5px' }} />
            </div>
            : null}
        </TableCell>
        <TableCell className='marieta'>
          <ReabrirRetornoButton id={retorno._id} />
        </TableCell>
      </TableRow>
    }
  </>);
}

const PaginaRetornos = () => {

  return <>
    <Layout>
      <Retornos />
    </Layout>

  </>

}

const atualizaRetornos = async (idEmpresa, mostrarHoje, setRetornosAbertos, setRetornosRetornados) => {
  try {
    const retornos = await getRetornosDaEmpresa(idEmpresa);

    let retornosAbertosFiltrados = retornos?.filter(retorno => retorno.status === 'Aguardando');
    let retornosRetornadosFiltrados = retornos?.filter(retorno => retorno.status === 'Retornou');

    if (mostrarHoje) {
      let today = new Date();
      let todayString = today.toISOString().split('T')[0];
      retornosRetornadosFiltrados = retornosRetornadosFiltrados.filter(retorno =>
        retorno?.hora_retorno?.startsWith(todayString)
      );
    }

    agrupaPorMotorista(retornosAbertosFiltrados, setRetornosAbertos);
    setRetornosRetornados(retornosRetornadosFiltrados);
  } catch (error) {
    console.error("Erro ao atualizar retornos:", error);
  }
}

const agrupaPorMotorista = (list, setList) => {
  const groupedByMotorista = list.reduce((acc, retorno) => {
    if (!acc[retorno.motorista]) {
      acc[retorno.motorista] = [];
    }
    acc[retorno.motorista].push({
      retorno
    });
    return acc;
  }, {});

  setList(groupedByMotorista);
}

export default PaginaRetornos;
