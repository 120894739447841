import React from 'react'
import './popUp.scss'

import Button from '../../componentes/button/Button'

const PopUp = (props) => {
    return (props.trigger) ? (
        <div className='popup'>
            <div className='popup-inner'>
                <i className="material-icons" onClick={() => props.setTrigger(false)}>close</i>

                <p className="conteudo-popUp">
                    {props.children}                
                </p> 

                <div className="botao-ok">
                    <Button onClick={() => props.setTrigger(false)} width={"50px"} color={"#FEFCFF"} background={'#6B4380'} border={'1px solid #6B4380'} text={"OK"}/>
                </div>
            </div>
        </div>
    ): "";

}

export default PopUp