import React from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableContainer, TableFooter, TableRow, TablePagination, Paper } from '@mui/material';

function TablePanel(props) {
  const {
    children = [],
    value = 0,
    index = 0,
    rowCount = 0,
    rowsPerPage = 10,
    page = 0,
    onPageChange = () => {},
    onRowsPerPageChange = () => {},
    columns = [],
    ...other
  } = props;

  console.log(other);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          <div style={{ display: 'flex', height: 'auto' }}>
            <Box p={3} padding={0} marginTop={3} style={{ flex: 1 }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column, idx) => (
                        <TableCell key={idx}><b>{column}</b></TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {children}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>

          <Table style={{ display: 'flex' }}>
            <TableFooter style={{ margin: 'auto', display: 'flex', flexWrap: 'wrap' }}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 40, { label: 'All', value: -1 }]}
                  count={rowCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </>
      )}
    </div>
  );
}


export const TableLine = ({columns})=>{

  return(
    <TableRow>  
      {columns.map((column, idx) => (
        <TableCell className='marieta' key={idx}>{column}</TableCell>
      ))} 
    </TableRow>
  )
}

export default TablePanel;