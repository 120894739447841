import './formFieldList.scss'
import Input from '../../../componentes/input/Input'

const FormFieldList = ({ inputFields, setInputField }) => {

    const handleChange = (name, e) => {
        const value = e;
        setInputField(prevInputFields =>
          prevInputFields.map(field =>
            field.name === name ? { ...field, value: value || '' } : field
          )
        );
    };

    return (
        <div className='form-field-list'>
        { inputFields !== 0 && inputFields !== undefined && 
            inputFields.map(({ label, name, value, placeholder, height, tipo }) => (
            <label className='icones-form' key={name}>
                {label}
                <Input
                    tipo={tipo}
                    name={name}
                    value={value}
                    onChange={(e) => handleChange(name, e)}
                    placeholder={placeholder}
                    height={height}
                />
            </label>
        ))}
        </div>
    )
}

export default FormFieldList