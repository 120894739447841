import { useMutation, useQueryClient } from 'react-query'
import React, { useState, useEffect } from 'react'
import { Oval } from  'react-loader-spinner'

import { criaColeta, editaEntregaPorId } from '../../../api/grandesEncomendasData'

import Autocompleta from '../../maps/autoComplete/autoComplete';
import SelecionaEndereco from '../../maps/selecionaEndereco/SelecionaEndereco';
import Button, { LocationButton } from '../../button/Button';
import Field from '../../form/field'
import PopUp from '../../popUpVazio/PopUpVazio';
import PopUpAlerta from './../PopUp'

import './../popUp.scss';
import './PopUpAtualizaColeta.scss'

const PopUpSolicitaEntrega = ({ trigger, setTrigger, setLoader, obj, data, atualiza, isEdit=false }) => {
    const [conteudoPopUp, setConteudoPopUp] = useState(""); 
    const [popUpAlerta, setPopUpAlerta] = useState(false);
    const [popUpMapa, setPopUpMapa] = useState(false);
    const [formData, setFormData] = useState([])
    const [carregando, setcarregando] = useState(false);

    const queryClient = useQueryClient()

    useEffect(() => {

        setFormData({
            nomeCliente: obj?.cliente_destinatario?.nome_cliente_parada || '',
            endereco: [{"long_name":obj?.cliente_destinatario?.endereco_parada.split(",")[0]}, {"long_name":obj?.cliente_destinatario?.bairro_parada},  {"long_name":obj?.cliente_destinatario?.cidade_parada},  {"short_name":obj?.cliente_destinatario?.estado_parada } ]|| '',
            numeroEndereco: Number(obj?.cliente_destinatario?.endereco_parada.split(",")[1]),
            complementoEndereco: obj?.cliente_destinatario?.complemento_parada || '',
            celular: obj?.cliente_destinatario?.telefone_cliente_parada || '',
            nota: obj?.n_nota_fiscal || '',
            volume: obj?.volume || '',
            observacao: obj?.observacao_parada || '',
        });

    }, [data, obj]);
      

    const inputFields = [
        { label: 'Complemento:', name: 'complementoEndereco', placeholder: 'ex.: apto 104', value: obj?.cliente_destinatario.complemento_parada || ''},
        { label: 'Nome do Cliente: *', name: 'nomeCliente', placeholder: '', value: obj?.cliente_destinatario.nome_cliente_parada || '' },
        { label: 'Celular do Cliente (WhatsApp): DDD+Número *', name: 'celular', placeholder: 'ex.:  43998899776', value: obj?.cliente_destinatario.telefone_cliente_parada || '' },
        { label: 'Número da Nota:', name: 'nota', placeholder: '', value: obj["n_nota_fiscal"]},
        { label: 'Volume: (ex: 1) *', name: 'volume', placeholder: '', value: obj["volume"]},
        { label: 'Observações: ', name: 'observacao', placeholder: '', height: '50px', value: obj["observacao_parada"]},
    ];
    
    const handleMundancaEndereco = (e, tipo) => {
       // console.log(e, formData, tipo)

        if(tipo === "autoComplete"){
            var novo = [e[1], e[2], e[3], e[4], e[5]]
            setFormData(prevState => ({
                ...prevState,
                ['endereco']: novo
            }));    

        }else if(tipo === "selectEndereco"){

            var novo = [e[1], e[2], e[3], e[4], e[5]]
            console.log(novo)
            setFormData(prevState => ({
                ...prevState,
                ['endereco']: novo
            }));

        }

        //console.log(formData)
    };

    const handleMundancaNumeroEndereco = (e) => {
        setFormData(prevState => ({
            ...prevState,
            ['numeroEndereco']: e
        }));
    };
    
    const lidarRegistroColeta = () => {
        try {
            setcarregando(true)
            
            const requiredFields = ['nomeCliente', 'endereco','celular', 'volume', "numeroEndereco"];

            if (requiredFields.some((field) => !formData[field] || formData[field] === '')) {
                setConteudoPopUp('Preencha todos os campos do formulário.');
                setPopUpAlerta(true);
                setcarregando(false)
                return;
            }

            
            if(Number.isNaN(Number(formData["celular"].replace(/\D/g, '')))){
                formData["celular"] = formData["celular"].replace(/\D/g, '')
                setConteudoPopUp('Os campos Celular do Cliente.');
                  
                setPopUpAlerta(true);
                setcarregando(false)
                return;
            }
            
            
            //console.log(formData)
            editaColeta.mutate({ dados: formData, _id: obj?._id })

        } catch (error) {
            console.error("Error handling delivery request:", error);
            setLoader(false);
        }
    };

    const onDeliveryRegistrationSucess = (e) =>{
        queryClient.invalidateQueries();
        if(e["status"] === 201){
            alert('Coleta alterada com sucesso!')
            setcarregando(false)
            setTrigger(false);
            setFormData({
                nomeCliente: '',
                endereco: '',
                complementoEndereco: '',
                numeroEndereco: '',
                celular: '',
                nota: '',
                volume: '',
                observacao: '',
            });
            atualiza(true)
        }
    }

    const onDeliveryRegistrationError = (e) =>{
        setcarregando(false)
        alert(e.response.data.data)
    }



    const editaColeta =  useMutation(editaEntregaPorId, {
        onSuccess: (e) => {
            onDeliveryRegistrationSucess(e);
          
        },
        onError: (e) => {
            onDeliveryRegistrationError(e);
        }
    });
      

    return (
        trigger ?
            <PopUp trigger={trigger} setTrigger={setTrigger}>
                <div className='popUpConteudo'>
                    <div className='popUpConteudo-2'>
                        <div style={{ color:"#BCB0B0", fontSize:"12px", marginBottom:"15px"}}> {"(*) - Campos Obrigatórios"}</div>

                        <SelecionaEndereco
                            trigger={ popUpMapa }
                            setTrigger={setPopUpMapa}
                            setaEndereco={(e) => handleMundancaEndereco(e, 'selectEndereco')}
                            setaNumero={handleMundancaNumeroEndereco}
                            style={{ display: popUpMapa ? 'block' : 'none' , width: 'auto', overflow: 'hidden'}}
                        />
                        {carregando == true &&
                            <div className='loader'>
                                <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={carregando} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
                            </div>
                        }

                        <div style={{display: 'flex'}}>
                            <div >
                                Endereço: *
                                <Autocompleta inputValor={obj["cliente_destinatario"]["endereco_parada"]} setaEndereco={(e) => handleMundancaEndereco(e, 'autoComplete')} style={{ display: popUpMapa ? 'none' : 'block', marginBottom:"15px", width:"400px"}} />
                            </div>
                            <LocationButton
                                trigger={popUpMapa}
                                setTrigger={setPopUpMapa}
                            />
                        </div>
                                            
                        {inputFields.map(({ label, name, placeholder, type, height, value }) => (
                            <Field
                                key={name}
                                label={label}
                                name={name}
                                value={formData[name]}
                                onChange={(e) => setFormData(prevState => ({ ...prevState, [name]: e }))}
                                placeholder={placeholder}
                                type={type}
                                width={"400px"}
                                height={height}
                            />
                        ))}

                        <div className='buttonRegistrar'>
                            <Button 
                                onClick={() => {lidarRegistroColeta()}}
                                width={"100px"}
                                color={"#ffffff"}
                                background={"rgb(66, 175, 60)"}
                                border={"rgb(66, 175, 60)"}
                                text={"Atualizar"} 
                                margin={'auto'}
                            />

                        </div>
                    </div>
                    
                </div>
                <PopUpAlerta trigger={popUpAlerta} setTrigger={setPopUpAlerta}>
                    {
                        <div>
                            {conteudoPopUp}
                        </div>
                    }
                </PopUpAlerta>
            </PopUp>
            : null
    );
};

export default PopUpSolicitaEntrega;
