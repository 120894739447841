import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const FourStarRating = ({ rating }) => {
  return (
    [1, 2, 3, 4].includes(rating) ?
    <div style={{ display: 'flex', alignItems: 'center', margin: 'auto 3px', height: 'auto', backgroundColor: "rgb(255 255 246 / 30%)", borderRadius: '15px', padding:'3px'}}>
      {[1, 2, 3, 4].map((value) => (
        <div key={value}>
          {value <= rating? (
            <StarIcon style={{ color: 'gold', height: '15px', margin: '0px', padding: '0px' }} />
          ) : (
            <StarBorderIcon style={{ color: 'gold', height: '15px', margin: '0px', padding: '0px' }} />
          )}
        </div>
      ))}
    </div>
    :null
  );
};

export default FourStarRating;

