import React, { useEffect,useState, useMemo} from 'react'
import {useQueryClient } from 'react-query'
import './tabela.scss'
import Pagination from '../pagination/Pagination'
import { Link } from "react-router-dom";
import { Oval } from  'react-loader-spinner'
import Cores from '../cores/Cores'


const Tabela = props => {
    const {
      onPageChange,
      totalCount,
      currentPage,
      pageSize,
      head,
      data
    } = props;

    const formatos = ["data", "destaque", "id", "dinheiro"]

    const queryClient = useQueryClient()

    const [loader, setLoader] = useState(true);


    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return data.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, data]);
    
    useEffect(() => {
        var lista = document.querySelectorAll("input");

        for ( var i = 0 ; i < lista.length ; i++ ){
            lista[i].checked = false
        }

       var updatedCheckedState = checkedState.map((intem, indice)=>{
            return ""
        })
        setCheckedState(updatedCheckedState)

    }, [currentPage]);

    const [checkedState, setCheckedState] = useState(new Array(20).fill("") );  
    const handleSelectAll = (status) => {

        let updatedCheckedState
        if(status){
            var lista = document.querySelectorAll("input");
    
            for ( var i = 0 ; i < lista.length ; i++ ){
                lista[i].checked = true
            }
            
            updatedCheckedState = currentTableData.map((intem, indice)=>{
                if(currentTableData[indice] !== undefined){
                    return currentTableData[indice]["_id"]
                }else{
                    return"i"
                }
            })
    
        }else{
            var lista = document.querySelectorAll("input");
    
            for ( var i = 0 ; i < lista.length ; i++ ){
                lista[i].checked = false
            }

            updatedCheckedState = checkedState.map((intem, indice)=>{
                return ""
            })
        }

        setCheckedState(updatedCheckedState);
        return updatedCheckedState
    }

    const handleChange = (position, status, value) => {
        
        var updatedCheckedState = checkedState.map((item, index) => 
        {
           if(index == position && status){
                return value
                
            }
            if(index == position && !status){
                return ''
                
            }
            return item
        });

        setCheckedState(updatedCheckedState);

        return updatedCheckedState
    };

    return (
        <div className='capa-tabela'>
            <Pagination onPageChange={onPageChange} totalCount={totalCount} siblingCount={1} currentPage={currentPage} pageSize={pageSize}/>

            { loader == true && currentTableData === "" &&
                <div className='loader'>
                    <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
                </div>
            }
            <table className="tabela-generica" style={{ borderSpacing: '10px 10px' }}>
                <thead className="head">
                    <tr className="th">
                        {
                            head.map((key) => 
                            (<th className="th" key={key.value}>{key.label}</th>))
                        }
                    </tr>
                </thead>
                <tbody>
                    {currentTableData.length > 0 &&
                    currentTableData.map((row, indexx) => (
                        <tr className="tr" key={indexx}>
                            { head.map((key) => {
                                const chave = Object.keys(key.tipo)

                                var valor
                                var color = 'rgb(78, 78, 78)'
                                var border = "none"
                                if(formatos.includes(chave[0])){
                                    switch (chave[0]) {
                                        case "data":
                                            const opcoesLocalizacao = {
                                                timeZone: 'America/Sao_Paulo',
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                            };
                                        
                                            var date = new Date(row[key.value]);
                                            valor = date.toLocaleString('pt-BR', opcoesLocalizacao);
                                            break;
                                        case "destaque":
                                            var tipo = key.tipo.destaque
                                            var cor = tipo[row[key.value]]
                                            color = Cores[cor]
                                            border = "1px solid "+ color
                                            valor = row[key.value]
                                            break;
                                        case "id":
                                            valor = row[key.value].substring(row[key.value].length - 4)
                                            color = Cores.azul
                                            border = "1px solid "+ color
                                            break;
                                        case "dinheiro":
                                            valor = row[key.value].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                            break;
                                        default:
                                    }
                                }else{
                                    valor = row[key.value]
                                }

                                const matches = key.value.split('[')
                                if(matches.length - 1 === 1){
                                    const extrai1 =  key.value.split('[')
                                    const extrai2 =  extrai1[1].split(']')
                                    const extrai3 =  extrai2[0].split('.')
                                    
                                    if(extrai3.length > 2){
                                        return(<td className="td" key={key.value}>{ row[extrai3[0]][extrai3[1]] + ". "+row[extrai3[0]][extrai3[2]] }</td>)
                                    }else{
                                        return(<td className="td" key={key.value}>{row[extrai3[0]][extrai3[1]] }</td>)
                                    }
                                }else{
                                    return(<td className="td" key={key.value}>
                                       <div style={{color:color, padding:"3px", display:"inline-block", alignItems:"center", width:"auto",  border: border, borderRadius:"10px"}} >
                                            {valor}
                                        </div> 
                                    </td>)
                                }
                            })}                    
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default Tabela

