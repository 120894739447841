import axios from 'axios'

/* SUMÁRIO

    1-login: Autenticação na plataforma

*/

const baseURL = '/login'

const loginApi = axios.create({
  baseURL: baseURL,
})

export const login = async (dados) => {
  // Autenticação na plataforma

  const res = await loginApi.get('/loginTaon', {params: {dados:dados}})
  return res
}

export const recuperarSenha = async (dados) => {
  // Autenticação na plataforma

  const resCredenciais = await loginApi.get('/loginTaon', {params: {dados:dados}})

  if(resCredenciais.status !== 201){
    return resCredenciais
  }

  const res = await loginApi.get('/enviaMensagemWhats', {params: {dados:resCredenciais.data}})

  if(res.status !== 201){
    return res
  }
  
  return res
}