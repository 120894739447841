let map: google.maps.Map;
let marker: google.maps.marker.AdvancedMarkerElement;
let infoWindow: google.maps.InfoWindow;
let autocomplete: google.maps.places.Autocomplete

/*
ESTA FUNÇÃO É USADA PARA ESTUDOS E DESENVOLVIMENTO, para ambiente de produção, ver componentes AutoComplte e SelecionaEndereço
Essa função cria um Mapa COMPLETO com campo input autocomplete e marcador

Documentação utilizada pode ser encontrada em <https://developers.google.com/maps/documentation/javascript/examples>
*/

export async function initMap(): Promise<void> {
  const [ Map ,  AdvancedMarkerElement ] = await Promise.all([
    window.google.maps.importLibrary("marker"),
    window.google.maps.importLibrary("places")
  ]);

  map = new window.google.maps.Map(
    document.getElementById("map") as HTMLElement,
    {
      center: { lat: -23.4273, lng: -51.9375 },  //
      mapId: '4504f8b37365c3d0',
      zoom: 14,
      mapTypeControl: false,
    }
  );
  
  const card = document.getElementById("pac-card") as HTMLElement;

  const input = document.getElementById("pac-input") as HTMLInputElement;

  const options = {
    fields: ["address_components",  "geometry"],
    type: ["address", "establishment", "geocode"],
    strictBounds: false,
  };

  map.controls[google.maps.ControlPosition.TOP_LEFT].push(card);

  autocomplete = new google.maps.places.Autocomplete(input, options);
  autocomplete.bindTo("bounds", map);

  infoWindow = new google.maps.InfoWindow();
  const infowindowContent: any = document.getElementById(
    "infowindow-content"
  ) as HTMLElement;

  infoWindow.setContent(infowindowContent);

  marker = new window.google.maps.marker.AdvancedMarkerElement({
    map,
    gmpDraggable: true,
  });

  autocomplete.addListener("place_changed", () => {
    infoWindow.close();

    const place = autocomplete.getPlace();

    if (!place.geometry || !place.geometry.location) {
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }
    marker.position = place.geometry.location;

    infowindowContent.children["place-name"].textContent = place.name;
    infowindowContent.children["place-address"].textContent =
      place.formatted_address;
      infoWindow.open(map, marker);
  });
}

declare global {
  interface Window {
    initMap: () => void;
  }
}
window.initMap = initMap;
export {};