import React, { useEffect } from 'react';
import { initMap } from './mapa.ts'
import '../mapa.scss'

const MapaAvancado = ({ setaEndereco }) => {
  useEffect(() => {
    initMap();
  }, []);

  return (
    <div>
      <div className="pac-card" id="pac-card">
        <div id="pac-container" style={{width: '100%', height: '25px', display: 'flex'}}>
          <input id="pac-input" type="text" placeholder="Digite o endereço" style={{width: '500px', height: 'auto'}}/>
        </div>
      </div>
      <div id="map" style={{width: '100%', height: '100vh'}}></div>
      <div id="infowindow-content">
        <span id="place-name" className="title"></span><br />
        <span id="place-address"></span>
      </div>
    </div>
  );
};

export default MapaAvancado;