let map: google.maps.Map;
let marker: google.maps.Marker;
let geocoder: google.maps.Geocoder;

/**
 * Mapa com marcador, usa setaEndereço para mudar valor de variável com base na posição do marcador
 * https://developers.google.com/maps/documentation/javascript/reference/places-widget?hl=pt-br
 */

export async function initMap(setaEndereco: (components: google.maps.GeocoderAddressComponent[]) => void, setaNumero: (num: number) => void): Promise<void> {
  const [Map, Marker, Places, Geocoding] = await Promise.all([
    window.google.maps.importLibrary("maps"),
    window.google.maps.importLibrary("marker"),
    window.google.maps.importLibrary("places"),
    window.google.maps.importLibrary("geocoding"),
  ]);

  map = new window.google.maps.Map(document.getElementById("map") as HTMLElement, {
    center: { lat: -23.4273, lng: -51.9375 },
    mapId: '4504f8b37365c3d0',
    zoom: 14,
    mapTypeControl: false,
    fullscreenControl: true,
    streetViewControl: false,
    zoomControl: true,
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_CENTER,
    },
    disableDefaultUI: true,
    keyboardShortcuts: false,
  });

  marker = new window.google.maps.Marker({
    map,
    position: { lat: -23.4273, lng: -51.9375 },
    draggable: true,
  });

  geocoder = new window.google.maps.Geocoder();
}

export const handleSelectAddress = (setaEndereco: (components: google.maps.GeocoderAddressComponent[]) => void, setaNumero: (num: number) => void) => {
  if (!marker || !marker.getPosition()) {
    console.error("Marker position is not set.");
    return;
  }

  const position = marker.getPosition();
  if (!position) {
    console.error("Marker position is not available.");
    return;
  }

  const latLng = { lat: position.lat(), lng: position.lng() };

  geocoder.geocode({ location: latLng }, (results, status) => {
    if (status === "OK") {
      if (results && results[0]) {
        const address_components = results[0].address_components;

        setaEndereco(address_components);

        const streetNumberComponent = address_components.find(component => component.types.includes('street_number'));
        if (streetNumberComponent) {
          setaNumero(parseInt(streetNumberComponent.long_name, 10)); // Converte para número
        }

        const info = document.getElementById('info');
        let infoContent = '';

        address_components.forEach(component => {
          infoContent += `<div><strong>${component.types[0]}:</strong> ${component.long_name}</div>`;
        });

        if (info) {
          info.innerHTML = infoContent;
        }

      } else {
        console.log("GEOCODE: Nenhum endereço foi encontrado para essa localização.");
      }
    } else {
      console.error("Geocoder falhou: " + status);
    }
  });
}

// Se você não precisa que `initMap` seja uma função global, não declare isso aqui
// declare global {
//   interface Window {
//     initMap: (setaEndereco: (components: google.maps.GeocoderAddressComponent[]) => void, setaNumero: (num: number) => void) => Promise<void>;
//   }
// }

// Se você quiser que a função seja global, defina uma função global diferente
window.initMap = initMap as unknown as () => void; // Adapta o tipo para uma função sem parâmetros

export {};
