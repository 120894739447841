import React, { useEffect } from 'react';
import { initMap, handleSelectAddress } from './selecionaEndereco.ts';
import Button from '../../button/Button.jsx';
import '../mapa.scss';
import './selecionaEndereco.scss';

const SelecionaEndereco = ({ trigger, setTrigger, setaEndereco, setaNumero, style }) => {
  useEffect(() => {
    initMap(setaEndereco, setaNumero);
  }, []);
  
  function handleSelecionaEndereco() {
    handleSelectAddress(setaEndereco, setaNumero);
  }

  return (
    <div style={style} position='absolute'>
      <div id="map" style={{ width: '800px', height: '400px' }}></div>
      <div id="info" style={{ width: '100%' }}></div>

      <div style={{ display: 'flex', bottom: '40px' }}>
        <Button onClick={handleSelecionaEndereco} width='auto' margin={'auto'}>
          <i className="fas fa-map-marker-alt"></i> Selecione o endereço
        </Button>
      </div>
    </div>
  );
};

export default SelecionaEndereco;