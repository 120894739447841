let autocomplete: google.maps.places.Autocomplete

/*
 *Cria componente autocomplete e atualiza endereço usando função setaEndereco
 */
 
export async function iniciaAutocomplete(setaEndereco: (endereco: any) => string): Promise<void> {
  const [ Map ] = await Promise.all([
    window.google.maps.importLibrary("places")
  ]);
  
  const input = document.getElementById("pac-input") as HTMLInputElement;
  const options = {
    fields: ["address_components"],
    type: ["address", "establishment", "geocode"],
  };


  autocomplete = new google.maps.places.Autocomplete(input, options);


  autocomplete.addListener("place_changed", () => {
    const place = autocomplete.getPlace();

    if (!place) {
      window.alert("No details available for input: '" + place + "'");
      return;
    }

    setaEndereco(place?.address_components)
  });
}

declare global {
  interface Window {
    iniciaAutocomplete: () => void;
  }
}

export {};