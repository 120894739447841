import React, { useState } from "react";
import {useMutation } from 'react-query'
import { useNavigate } from "react-router-dom";
import { Oval } from  'react-loader-spinner'
import "./recuperar.scss"

import Input from "../../componentes/input/Input"
import Button from "../../componentes/button/Button"
import Navbar from '../../componentes/navbar/Navbar'
import PopUp from '../../componentes/popUp/PopUp'

import logo from "./logo-id.png"

import {recuperarSenha} from '../../api/loginData'


const Recuperar = () => {
  const navigate = useNavigate();

  const [ID, setID] = useState("");
  const [loader, setLoader] = useState(false);
  const [buttonPopUp, setButtonPopUp] = useState(false);
  const [conteudoPopUp, setConteudoPopUp] = useState("");
  
  const recuperarSenhaTaon=  useMutation(recuperarSenha, {
    onSuccess: (e) => {
      setLoader(false)
      setButtonPopUp(true)
      setConteudoPopUp("Senha enviada com sucesso")
    },
    onError: (e) => {
      setLoader(false)
      setButtonPopUp(true)
      setConteudoPopUp(e.response.data)
    }
    
  });


  const lidarRecuperarSenha = () => {
    setLoader(true)
    
    if (!ID ) {
      setLoader(false)
      setButtonPopUp(true)
      setConteudoPopUp("Preencha o campo ID corretamente")
      
      return;
    }

    var credinciais = {senha:"recuperar-senha", ID}

    recuperarSenhaTaon.mutate(credinciais)
  };

  const lidarVoltarLogin = () => {
    navigate("/login");    
  };

  return (
    <div className="pagina">
      <div className="cabeca">
        <Navbar/>
      </div>

      <div className="corpo">
        <div className="tela-recuperar">
          <div>
          <div className="logo-id">
            <img src={logo} alt="testsss"  style={{ width: "30px", height:"30px" }}  />
          </div>
          <div className="entrada">
            <div className="recuperar-infomacoes">
              Digite seu ID e enviaremos sua senha para o número de whatsApp cadastrado.
            </div>
            <Input type="text" placeholder="Digite o ID da empresa" border={"1px solid #FAD40C"} width={"210px"}  value={ID} onChange={setID}/>

            <div  style={{ marginTop: "10px" }}>
              <Button width={"100px"} color={"#EEECEF"} background={'#6B4380'} border={'1px solid #FAD40C'} text={"Enviar"} onClick={() => {lidarRecuperarSenha()}}/>
            </div>

            {loader === true &&
              <div className='loader'>
                  <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
              </div>
            }
          </div>


          <div className="lembrete">
            <div className="frases">
              <div className="textos-lembretes" onClick={() => {lidarRecuperarSenha()}}>Enviar novamente</div>
              <div className="textos-lembretes" onClick={() => {lidarVoltarLogin()}}>Voltar para o login</div>

            </div>
          </div>
          </div>
        </div>
      </div>

      <PopUp trigger={buttonPopUp} setTrigger={setButtonPopUp}>
        {
          <div>
            {conteudoPopUp}
          </div>
        }
      </PopUp>
    </div>
  );
};

export default Recuperar;