/* SUMÁRIO
  getCategory: Procura categoria (estrelas) por ID de empresa.
*/

import axios from 'axios'

const baseURL = '/client'

const clientApi = axios.create({
  baseURL: baseURL,
})

/**
 * Procura categoria por número de id da empresa.
 * @param {string} id - id da empresa.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const getCategory = async (id) => { 
  const res = await clientApi.get('/category', {params: {id: id}})
  return res.data;
}

/**
 * Procura limite simultâneo por número de id da empresa.
 * @param {string} id - id da empresa.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const getLimiteSimultaneo = async (id) => { 
  const res = await clientApi.get('/limiteSimultaneo', {params: {id: id}})
  return res.data;
}


/**
 * Procura fluxo da cidade por número de id da empresa.
 * @param {string} id - id da empresa.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const getFluxo = async (id) => { 
  const res = await clientApi.get('/fluxo', {params: {id: id}})
  return res.data;
}