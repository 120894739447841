import React, { useState, useEffect } from "react";
import { Oval } from  'react-loader-spinner'
import {setLogger, useQuery, useMutation, useQueryClient } from 'react-query'
import { Tabs, Tab } from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/Store';

import TabPanel, { TableLine } from './componentes/TabPanel';
import { Title } from '../../componentes/text/Text';
import Input from "../../componentes/input/Input"
import { EditNoteButton, Button } from "../../componentes/button/Button";
import PopUp from "../../componentes/popUpVazio/PopUpVazio"
import Tabela from "../../componentes/tabela/tabela"

import {consultaSaldo, enviaMensagemMotorista, procuraRecibo, procuraTodosRecibos, cancelaCompraMotorista } from '../../api/financeiroMotoristasData'

import "./financeiroMotoristas.scss";
import moto from "./gif/moto.gif"

export const ClientPage = () => {

    const id = JSON.parse(localStorage.getItem("usuarioCash"))[0]["id"]

    let PageSize = 10;

    const cabecaTabela = [
        { value: 'createdAt', label: 'Data', tipo: {"data":"dia/mes/ano"}},
        { value: 'nome', label: 'Motorista', tipo: {"padrao":"padrao"}},
        { value: 'valor', label: 'Valor', tipo: {"dinheiro":"dinheiro"}},
    ]

    const [value, setValue] = useState(0);
    const [inpuCpf, setInputCpf] = useState("");
    const [valor, setValor] = useState("");
    const [loader, setLoader] = useState(false);
    const [popUpAguarda, setPopUpAguarda] = useState(false);
    const [retornoPessoa, setRetornoPessoa] = useState("");
    const [idCheck, setIdCheck] = useState("");
    const [dataTabela, setDataTabela] = useState([]);
    const [dataPagosTabela, setDataPagosTabela] = useState([]);
    const [ativaQuery, setAtivaQuery] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [tabela, setTabela] = useState(0)

    const dadosRepasse = useQuery([{_id:idCheck}], procuraRecibo, {refetchInterval: 3000,  enabled: ativaQuery});
    const dadosTabela = useQuery([{id_empresa:id}], procuraTodosRecibos, {refetchInterval: 30000});

    const lidarConsulta = () =>{
        setLoader(true)

        if(inpuCpf === "" || valor=== ""){
            alert("Insira o cpf e um valor.")
            setLoader(false)
            return
        }

        consultaSaldoMotorista.mutate({cpf:inpuCpf, valor:valor})
    }

    const lidarMensagem = (pessoa) =>{
        var retorno = pessoa
        retorno["id_empresa"] = JSON.parse(localStorage.getItem("usuarioCash"))[0]["id"]
        retorno["valor"] = valor
        setRetornoPessoa(retorno)
        enviaMensagem.mutate(retorno)
    }

    const lidarTimer = async (id) => {
       await delay(300000);
       setAtivaQuery(false)
       setPopUpAguarda(false)
       cancelaCompra.mutate({id:id})
    }
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    )

    const cancelaCompra =  useMutation(cancelaCompraMotorista, {
        onSuccess: (e) => {
            if(e.data.data === "Efetivado"){
                setIdCheck("")
                setValor("")
                setInputCpf("")

            }else if(e.data.data === "Cancelada" && popUpAguarda !== false){
                alert("Operação cancelada. Tempo expirado.")
                setPopUpAguarda(false)
                setIdCheck("")
                setValor("")
                setInputCpf("")
            }

        },
        onError: (e) => {
            setIdCheck("")
            setValor("")
            setInputCpf("")
        }
    });  
    
    const enviaMensagem =  useMutation(enviaMensagemMotorista, {
        onSuccess: (e) => {
            setIdCheck(e.data.dados["_id"])
            setAtivaQuery(true)
            setPopUpAguarda(true)
            setValor("")
            setInputCpf("")
            lidarTimer(e.data.dados["_id"]);
            
        },
        onError: (e) => {
            alert(e)
            setValor("")
            setInputCpf("")
        }
    });   
    const consultaSaldoMotorista =  useMutation(consultaSaldo, {
        onSuccess: (e) => {
          setLoader(false)
          if(e.status === 201){
            setRetornoPessoa(e.data.pessoaEncontrada)
            lidarMensagem(e.data.pessoaEncontrada)
          }else{
            alert(e.data.data)
            setValor("")
            setInputCpf("")
          }
          
        },
        onError: (e) => {
            setLoader(false)
            setValor("")
            setInputCpf("")
            alert("Erro ao consultar dados.")
        }
    });
    
    useEffect(() => {

    if (dadosRepasse.isSuccess) {
        var recibo = dadosRepasse.data.data.data
        if(recibo.length !== 0){
            if(recibo[0]["status_check"] === "Cancelado"){
                setAtivaQuery(false)
                setPopUpAguarda(false)
                setIdCheck("")
                alert("Operação não aceita ou tempo esgotado.")
            }else if(recibo[0]["status_check"] === "Efetivado"){
                setAtivaQuery(false)
                setPopUpAguarda(false)
                setIdCheck("")
                alert("Operação confirmada com sucesso.")
            }
        }
    }
    },[ dadosRepasse.data ])

    useEffect(() => {
        if (dadosTabela.isSuccess) {
          setDataTabela(dadosTabela.data.data.dataAberto)
          setDataPagosTabela(dadosTabela.data.data.dataPago)
        }
    },[ dadosTabela.data ])    

    return (
        <div className="main">
            <div className="tela-principal-retornos">
                <Cabecalho value={value} setValue={setValue} setTabela={setTabela}/>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    {loader == true &&
                        <div className='loader-pay'>
                            <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
                        </div>
                    }
                        <div style={{ marginBottom: '8px' }}>
                            <p>CPF Motorista</p>
                            <Input value={inpuCpf} onChange={setInputCpf} placeholder={"Digite aqui o CPF"} type={"cpf"}/>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'end', gap: '8px', justifyContent:"space-between", width:"100%" }}>
                            <div>
                                Valor
                                <Input value={valor} width='90px' onChange={setValor} type={"dinheiro-v2"}/>
                            </div>
                            <Button className='botaoConsultar' onClick={() => lidarConsulta()}> Consultar</Button>
                        </div>
                    </div>

                    <div style={{ flexGrow: 1, alignItems: 'flex-start', marginLeft: '50px' }}>
                        {tabela === 0 &&
                            <Tabela name={"table"} value= {"value"} data={dataTabela} head={cabecaTabela} onPageChange={page => setCurrentPage(page)} totalCount={dataTabela.length} currentPage={currentPage} pageSize={PageSize}></Tabela>}
                        {tabela === 1 &&
                            <Tabela name={"table"} value= {"value"} data={dataPagosTabela} head={cabecaTabela} onPageChange={page => setCurrentPage(page)} totalCount={dataPagosTabela.length} currentPage={currentPage} pageSize={PageSize}></Tabela>}
                    </div>
                </div>
            </div>


          <PopUp trigger={popUpAguarda} setTrigger={setPopUpAguarda}>
            { idCheck !== "Finalizada" &&
              <div  style={{justifyContent:"center", width:"100%", padding:"15px"}}>
                <img src={moto} alt="Carregando..." />

                <div style={{display:"flex", justifyContent:"center", width:"100%"}}>
                    Aguardando a confirmação do motorista...
                </div>
              </div>
            }
          </PopUp>
        </div>
    );
}

const Cabecalho = ({ value, setValue, setTabela}) => {
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setTabela(newValue)
    };

    return <>
        <div className="titulo-grandes-entregas">
            <Title Icon={ShoppingCartOutlinedIcon} >
                Convênio Taon
            </Title>

            <div className="tabs-container">
                <Tabs value={value} onChange={handleChange} aria-label="Retornos">
                    <Tab label="Abertos" />
                    <Tab label="Pagos" />
                </Tabs>
            </div>
        </div>
    </>
}

export default ClientPage;