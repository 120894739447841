import axios from 'axios'

/* SUMÁRIO
  procuraFiltroData: Procura dados pelo filtro de data
  procuraPorFiltro: Procura dados pelo filtro de data, auxiliar

  editaEntregaPorId: edita coleta registrada

  criaColeta: Solicita e cria coleta

  deletaPorId: Deleta coleta por Id
*/

const baseURL = '/grandesEncomendas'

const grandesEncomendasApi = axios.create({
  baseURL: baseURL,
})

export const procuraFiltroData = async ({ queryKey }) => {
  //Procura dados pelo filtro de data

  var value = queryKey[0]
  const res = await grandesEncomendasApi.post('/procuraFiltroData', value)
  return res
}

export const procuraPorFiltro = async ({ queryKey }) => {
  //Procura dados pelo filtro de data

  var value = queryKey[0]
  const res = await grandesEncomendasApi.post('/procuraFiltroData', value)
  return res
}


export const editaEntregaPorId = async ( dados ) => {
  //edita coleta registrada
 
  const res = await grandesEncomendasApi.post('/editaEntregaPorId', {params: {dados:dados.dados, _id: dados._id}})

  return res
  
}

export const criaColeta = async (dados) => {
  // Solicita e cria coleta

  // Procura dados empresa
  const res = await grandesEncomendasApi.get('/idTaon', {params: {dados:dados}})
  if(res.status !== 201){
    return res
  }

  var dadosRequisicao = {
    "dadosRemetente": res.data,
    "dadosDestino": dados
  }

  //Criar coleta
  const resultadoCriaColeta = await grandesEncomendasApi.post('/registraGrandesEncomendasBanco', dadosRequisicao)
  return resultadoCriaColeta
}

export const deletaPorId = async (dados) => {
  // Deleta coleta por Id
  
  const resultadoCriaColeta = await grandesEncomendasApi.post('/deletaPorId', dados)
  return resultadoCriaColeta
}

