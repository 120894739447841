
import React from 'react';
import './input.scss'

const Input = (props) => {
    const handleChange = props.onChange || (() => {});

    const formatarCelular = (celular) => {
        // Remove todos os caracteres não numéricos
        celular = celular.replace(/\D/g, '');
      

        if (celular.length > 0) {
            celular = '(' + celular;
        }
        if (celular.length > 3) {
            celular = celular.slice(0, 4) + ') ' + celular.slice(4);
        }
        if (celular.length > 4) {
            celular = celular.slice(0, 5) + ' ' + celular.slice(5);
        }
        if (celular.length > 5) {
            celular = celular.slice(0, 7) + celular.slice(7);
        }
        if (celular.length > 6) {
            celular = celular.slice(0, 8) +  ' ' + celular.slice(8);
        }
        if (celular.length > 13) {
            celular = celular.slice(0, 13) + '-' + celular.slice(13);
        }
        return celular;
    };

    const handleChangeCell = (event) => {
        const value = event
        return formatarCelular(value);
    };

    const formatarCPF = (cpf) => {
        // Remove todos os caracteres não numéricos
        cpf = cpf.replace(/\D/g, '');
      
        // Aplica a máscara
        cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
        cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
      
        return cpf;
    };

    const handleChangeCpf = (event) => {
        const value = event
        return formatarCPF(value);
    };

    const handleChangeNumero = (value) => {
        //console.log(value)

        const numericValue = value.replace(/[^\d,.]/g, '');
        const displayValue = numericValue.replace('.', ',');

        return displayValue
    };

    const handleChangeDinheiro = (e) => {
        const formattedValue = handleChangeNumero(e.target.value);
        props.onChange(formattedValue);
    };


    const inputType = props.type || "text";

    return (
        <div className="input" style={{width: props?.width, border: props?.border, height: props.height, type: props.type, margin: props.margin}}>
            {inputType === "text" &&
                <input type={ props.type || "text" } 
                    name={props?.name}
                    value={props?.value}
                    border={props?.border}
                    onChange={(e) => handleChange(e?.target?.value)}
                    placeholder={props?.placeholder} 
                    className="placeholder" 
                    readOnly={props?.readOnly ?? false}
                />
            }
            {inputType === "celular" &&
                <input type="text"
                name={props.name}
                value={props.value}
                border={props?.border}
                onChange={e => props.onChange(handleChangeCell(e.target.value))}
                placeholder={props.placeholder} 
                className="placeholder"
                readOnly={props?.readOnly ?? false}
                maxLength="18"
                />
            }
            {inputType === "textoArea" &&
                <textarea 
                name={props.name}
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                placeholder={props.placeholder} 
                className="placeholder" 
                />
            }
            {inputType === "cpf" &&
                <input type="text"
                name={props.name}
                value={props.value}
                onChange={e => props.onChange(handleChangeCpf(e.target.value))}
                placeholder={props.placeholder} 
                className="placeholder" 
                maxLength="14"
                />
            }
            {inputType === "number" &&
                <input type="number"
                name={props.name}
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                placeholder={props.placeholder} 
                className="placeholder" 
                readOnly={props?.readOnly ?? false}
                />
            }
            {inputType === "dinheiro" &&
                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>{"R$ "}
                </div>
                    <input
                    type="text"
                    name={props.name}
                    value={props.value}
                    onChange={handleChangeDinheiro}
                    placeholder={props.placeholder}
                    className="placeholder"
                    readOnly={props?.readOnly ?? false}
                    />
                </div>
            }
            {inputType === "dinheiro-v2" &&
                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>{"R$ "}
                </div>
                <input type="number"
                name={props.name}
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                placeholder={props.placeholder} 
                className="placeholder" 
                readOnly={props?.readOnly ?? false}
                />
                </div>
            }
        </div>
    )
}

export default Input

