import React, {useState, useEffect} from 'react'
import Icon from '@mui/material/Icon';
import { useParams} from 'react-router-dom';

import axios from 'axios'

import ListaRastreio from './componentes/listaRastreio/ListaRastreio'

import PopUp from '../../componentes/popUp/PopUp';

import logo from "./Imagens/logo.png";
import furgao from "./Imagens/furgao.png";
import './rastreio.scss'

const Rastreio = () => {

    const [dadosRastreio, setDadosRastreio] = useState('');
    const [popUpIcone, setPopUpIcone] = useState(false);
    const [popUpConteudo, setPopUpConteudo] = useState("");
    const [popUpConteudoHistorico, setPopUpConteudoHistorico] = useState("");
    const [dadosRecebedor, setDadosRecebedor] = useState("");


    const opcoesLocalizacaoHora = {
        timeZone: 'America/Sao_Paulo',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',      
        minute: '2-digit',
    };

    const {id} = useParams();

   // const status = {"status":"Coletado", "createdAt": "2024-02-26T17:43:38.361+00:00", "_id": "ffG4dfg4jh8", "cliente_destinatario": {"endereco_parada":"Rua josé domingos de Oliveira, 629", "cidade_parada":"Londrina"} }
    const status_entrega = {"Aguardando":"Está a caminho da coleta", "Coletado":"A caminho para a entrega",  "Finalizada": "Entrega finalizada" }


    const baseURL = '/rastreio'
    const rastreioApi = axios.create({
        baseURL: baseURL,
    })

    const lidarIcone = (valor) => {
        if(valor === "historico"){
            var historico = dadosRastreio.obs_reenvio
            setPopUpConteudo("")
            setPopUpIcone(true)
            setDadosRecebedor("")
            setPopUpConteudoHistorico(historico)
        }else if(valor === "assinatura_destinatario"){
            var imagem = dadosRastreio[valor]
            setPopUpConteudo(imagem)
            setPopUpConteudoHistorico("")
            setDadosRecebedor(dadosRastreio["dados_recebedor"])
            setPopUpIcone(true)

        }
        
        else{
            var imagem = dadosRastreio[valor]
            setPopUpConteudo(imagem)
            setPopUpConteudoHistorico("")
            setDadosRecebedor("")
            setPopUpIcone(true)
        }
    };

    const buscarDados = async () => {
        //Função procura cobranças no banco de dados

        var resposta
        try {
          resposta =  await rastreioApi.get('/procurarPorId', {params: {id:id}})

          const opcoesLocalizacao = {
            timeZone: 'America/Sao_Paulo',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
            };
    
            var date = new Date(resposta.data.data["createdAt"]);
            var novaData = date.toLocaleString('pt-BR', opcoesLocalizacao);

            resposta.data.data["dataCriacao"] = novaData

            setDadosRastreio(resposta.data.data)
        } catch(err) {
          return console.error(err);
        }
    }

    useEffect(() => {

        buscarDados();
    }, []);

    return (
        <div >
            { dadosRastreio !== "" ? (
                <div className='rastreio'>
                    
                    <div className='cabeca'>
                        <img className="icone" src={logo}/>
                        
                        <div className="faxada">
                            <div className="texto">
                                O entregador Taon delivery está: {status_entrega[dadosRastreio["status"]]}
                            </div>
                            <div className="imagem-furgao">
                                <img className="icone-furgao" src={furgao}/>
                            </div>

                        </div>
                    </div>
                    <div className='abraca-corpo'>
                        <div className='corpo-rastreio'>
                            <div className='data'>
                                <div className='texto-data'>
                                    Solicitada às {dadosRastreio["dataCriacao"]}
                                </div>
                                <Icon className='icone-relogio'>access_time</Icon>
                            </div>
                            <div className='blocos-endereco'>
                                <div className='titulo'>
                                    Entrega em
                                </div>
                                <div>
                                    {dadosRastreio.cliente_destinatario.endereco_parada + ". " + dadosRastreio.cliente_destinatario.cidade_parada + "-" + dadosRastreio.cliente_destinatario.estado_parada}
                                </div>

                            </div>
                        </div>

                        <div className='corpo-rastreio'>
                            <div className='data'>
                                <div className='texto-data'>
                                    Código da Entrega: {dadosRastreio["_id"].substring(dadosRastreio["_id"].length - 4)}
                                </div>
                            </div>
                            <ListaRastreio status={dadosRastreio["status"]} />
                        </div>


                        <div className='corpo-rastreio-imagens'>
                            <div className='botoes-fotos'>
                                <div className='botao' onClick={()=>{lidarIcone("imagem_coleta")}}>
                                    <Icon className='icone-imagem'>camera_alt</Icon>
                                    <div className='titulo'> Coleta</div>
                                </div>

                                <div className='botao' onClick={()=>{lidarIcone("imagem_entrega") }}>
                                    <Icon className='icone-imagem'>camera_alt</Icon>
                                    <div className='titulo'> Entrega</div>
                                </div>

                                <div className='botao' onClick={()=>{lidarIcone("assinatura_destinatario")}}>
                                    <Icon className='icone-imagem'>draw</Icon>
                                    <div className='titulo'> Assinatura</div>
                                </div>

                                <div className='botao' onClick={()=>{lidarIcone("historico")}}>
                                    <Icon className='icone-imagem'>description</Icon>
                                    <div className='titulo'> Observações</div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                ):
                (
                    <div className='rastreio'>
                    
                        <div className='cabeca'>
                            <img className="icone" src={logo}/>
                            
                            <div className="faxada">
                                <div className="texto">
                                   Carregando...
                                </div>
                                <div className="imagem-furgao">
                                    <img className="icone-furgao" src={furgao}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            <PopUp trigger={popUpIcone} setTrigger={setPopUpIcone}>
                <div className='popUp-icone'>
                { popUpConteudo !== "" && 
                    popUpConteudo.map((img_src, index) => (
                    
                    <img key={index} className='capitura' style={{transformOrigin: 'center'}} src={img_src} />))
                }
                { dadosRecebedor !== "" &&
                    <div>
                        <div>
                            {"Nome:  " + (dadosRecebedor["nome"] === undefined ? "Não foi registrado ainda" : dadosRecebedor["nome"]) }
                        </div>
                        <div>
                            {"CPF :  " + (dadosRecebedor["cpf"] === undefined ? "Não foi registrado ainda" :  dadosRecebedor["cpf"])}
                        </div>
                    </div>
                }
                { popUpConteudoHistorico !== "" && 
                    <div className='borda-historico'>
                        <div className='cabeca-historico'>
                            <div>
                                Observação
                            </div>
                            <div>
                                Data
                            </div>
                        </div>
                        {popUpConteudoHistorico.map((obj, indice) =>{
                            return(
                            <div className='corpo-historico' key={indice}> 
                                <div>
                                    {obj.obs}
                                </div>
                                <div>
                                    {obj?.data !== undefined &&
                                    new Date(obj.data).toLocaleString('pt-BR', opcoesLocalizacaoHora)
                                    }
                                </div>
                            </div>
                            )
                            })
                        }
                    </div>
                }
                </div>
            </PopUp>
        </div>
    )
}

export default Rastreio