import React, {useState} from 'react'

import Input from '../input/Input'

const Field = ({ label, name, value, onChange, placeholder, height, width, type = 'text', readOnly = false}) => (
    <label className='icones-form' >
      {label}
    <Input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      height={height}
      width={width}
      readOnly={readOnly}
    />
    </label>
);


export default Field;