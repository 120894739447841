import React, {useState} from 'react'
import Icon from '@mui/material/Icon';

import './listaRastreio.scss'

const ListaRastreio = (prop) => {
    const status = {"Aguardando":"Está a caminho da coleta", "Coletado":"A caminho para a entrega",  "Finalizada": "Entrega finalizada" }
    var cor = "rgb(66, 175, 60)"
    var cont = 0
    var classeName = "icone-status-verde"

    return (
        <div className='bloco-repete'>
            {
                Object.keys(status).map((valor, indice)=>{

                    var icone
                    if(indice == 0){
                        icone = "place_outlined"
                    }else{
                        icone = "access_time"
                    }

                    if(cont == 1){
                        cor = "#999"
                        classeName= "icone-status"
                    }
                    if(valor == prop.status ){
                        cont++
                    }

                    return(
                        <div className='blocos-status' key={indice}>
                            <div className={classeName}>
                                <Icon className='icone-place' style={{color:cor}}>{icone}</Icon>
                            </div>
                            <div className='texto-status'  style={{border:"1px solid "+cor}}>
                                {status[valor]}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ListaRastreio


