import axios from 'axios'

/* SUMÁRIO
    consultaSaldo:consulta saldo do motorista
    enviaMensagemMotorista: Envia mensagem ao motorista
    procuraDadosRecibo: Procura recibo
    procuraTodosRecibos: Procura recibos da empresa
    cancelaCompraMotorista: Cancela compra do motorista
*/

const baseURL = '/financeiroMotoristas'

const financeiroMotoristasApi = axios.create({
  baseURL: baseURL,
})


export const consultaSaldo = async (valor) => { 
    //consulta saldo do motorista

    const res = await financeiroMotoristasApi.get('/consultaSaldo', {params: valor})

    return res
}

export const procuraRecibo = async ({ queryKey }) => {
    //Procura recibo

    var valor = queryKey[0]

    const res = await financeiroMotoristasApi.get('/procuraRecibo', {params: valor})

    return res
}

export const procuraTodosRecibos = async ({ queryKey }) => {
    //Procura recibos da empresa

    var valor = queryKey[0]

    const res = await financeiroMotoristasApi.get('/procuraTodosRecibos', {params: valor})

    return res
}

export const enviaMensagemMotorista = async (valor) => { 
    //Envia mensagem ao motorista

    const res = await financeiroMotoristasApi.post('/enviaMensagemMotorista', valor)

    return res
}

export const cancelaCompraMotorista = async (valor) => { 
    //Cancela compra do motorista

    const res = await financeiroMotoristasApi.post('/cancelaCompraMotorista', valor)

    return res
}

  