import React from "react";
import MenuLateral from "../menuLateral/Menulateral";
import NavBar from "../navbar/Navbar";
import './index.scss'

function Layout({ children }) {
  
    return (
      <div className="pagina">
        <div className="cabeca">
          <NavBar usuarioAtivo={true} />
        </div>
        <div className="corpo-home">
          <MenuLateral /> 
          
          <div className="conteudo-principal">
            { children }
          </div>
        </div>
      </div>
    );
}
  
  
  
  export default Layout;