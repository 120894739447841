import React from 'react'
import './popUpAlerta.scss'

import Button from '../button/Button'

const PopUpAlerta = (props) => {
    return (props.trigger) ? (
        <div className='popup-alert'>
            <div className='popup-inner-alert'>
            <i className="material-icons" onClick={() => props.setTrigger(false)}>close</i>
            <p className="conteudo-popUp">
                {props.children}                
            </p> 

            <div className="botao-ok">
                <Button onClick={() => props.setTrigger(false)} width={"50px"} color={"#FEFCFF"} background={'#6B4380'} border={'1px solid #6B4380'} text={"OK"}/>
            </div>
            </div>
        </div>
    ): "";

}

export default PopUpAlerta