import Layout from '../../componentes/layout';
import ClientPage from './Client';

const PaginaFinanceiroMotoristas = () => {

    return <>
      <Layout>
        <ClientPage />
      </Layout>
  
    </>
  
  }
  
export default PaginaFinanceiroMotoristas;
