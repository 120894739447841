import { useMutation, useQueryClient } from 'react-query'
import React, { useState, useEffect } from 'react'
import { Oval } from  'react-loader-spinner'

import { criaColeta, editaEntregaPorId } from '../../api/grandesEncomendasData'

import Autocompleta from '../maps/autoComplete/autoComplete';
import SelecionaEndereco from '../maps/selecionaEndereco/SelecionaEndereco';
import Button, { LocationButton } from '../button/Button';
import Field from '../form/field'
import PopUp from '../popUpVazio/PopUpVazio';
import PopUpAlerta from './PopUp'

import './popUp.scss';
import './popUpSolicitaEntrega.scss'

const PopUpSolicitaEntrega = ({ trigger, setTrigger, setLoader, id, data, isEdit=false }) => {
    const [conteudoPopUp, setConteudoPopUp] = useState(""); 
    const [popUpAlerta, setPopUpAlerta] = useState(false);
    const [popUpMapa, setPopUpMapa] = useState(false);
    const [formData, setFormData] = useState([])
    const [carregando, setcarregando] = useState(false);
    const [inputAutoComplete, setInputAutoComplete] = useState("");

    const queryClient = useQueryClient()

    useEffect(() => {
        const enderecoParada = data?.cliente_destinatario?.endereco_parada || '';
        const partesEndereco = enderecoParada.split(',').map(part => part.trim());
    
        const endereco = partesEndereco[0] || ''; 
        const numeroEndereco = partesEndereco[1] || ''; 

        setFormData({
            id_da_empresa: id,
            nomeCliente: data?.cliente_destinatario?.nome_cliente_parada || '',
            endereco: endereco,
            numeroEndereco: numeroEndereco,
            complementoEndereco: data?.cliente_destinatario?.complemento_parada || '',
            celular: data?.cliente_destinatario?.telefone_cliente_parada || '',
            nota: data?.n_nota_fiscal || '',
            volume: data?.volume || '',
            observacao: data?.observacao_parada || '',
        });

    }, [data, id]);
      

    const inputFields = [
        { label: 'Complemento:', name: 'complementoEndereco', placeholder: 'ex.: apto 104' },
        { label: 'Nome do Cliente: *', name: 'nomeCliente', placeholder: '' },
        { label: 'Celular do Cliente (WhatsApp): DDD+Número *', name: 'celular', placeholder: 'ex.:  043998899776', type:'celular'},
        { label: 'Número da Nota:', name: 'nota', placeholder: ''},
        { label: 'Volume: (ex: 1) *', name: 'volume', placeholder: ''},
        { label: 'Observações: ', name: 'observacao', placeholder: '', height: '50px'},
    ];
    
    const handleMundancaEndereco = (e, tipo) => {

        if(tipo === "autoComplete"){
            var novo = [e[1], e[2], e[3], e[4], e[5]]
            setFormData(prevState => ({
                ...prevState,
                ['endereco']: novo,
                ['numeroEndereco']: Number(e[0]["long_name"]),
            }));
            

        }else if(tipo === "selectEndereco"){
            console.log(e)

            var novo = [e[1], e[2], e[3], e[4], e[5]]
            setFormData(prevState => ({
                ...prevState,
                ['endereco']: novo,
                ['numeroEndereco']: Number(e[0]["long_name"]),
            }));

        }
    };

    const handleMundancaNumeroEndereco = (e) => {
        setFormData(prevState => ({
            ...prevState,
            ['numeroEndereco']: e
        }));
    };
    
    const lidarRegistroColeta = () => {
        try {
            setcarregando(true)
            
            const requiredFields = ['nomeCliente', 'endereco','celular', 'volume', "numeroEndereco"];

            if (requiredFields.some((field) => !formData[field] || formData[field] === '')) {
                setConteudoPopUp('Preencha todos os campos do formulário.');
                setPopUpAlerta(true);
                setcarregando(false)
                return;
            }

            
            if(Number.isNaN(Number(formData["celular"].replace(/\D/g, '')))){
                formData["celular"] = formData["celular"].replace(/\D/g, '')
                setConteudoPopUp('Os campos Celular do Cliente.');
                  
                setPopUpAlerta(true);
                setcarregando(false)
                return;
            }
            
            if (isEdit==true) editaColeta.mutate({ dados: formData, _id: data?._id })
            else registraNovaColeta.mutate( formData );

        } catch (error) {
            console.error("Error handling delivery request:", error);
            setLoader(false);
        }
    };

    const onDeliveryRegistrationSucess = (e) =>{
        queryClient.invalidateQueries();
        if(e["status"] === 201){
            setcarregando(false)
            setTrigger(false);
            setFormData({
                id_da_empresa: id,
                nomeCliente: '',
                endereco: '',
                complementoEndereco: '',
                numeroEndereco: '',
                celular: '',
                nota: '',
                volume: '',
                observacao: '',
            });
        }
    }

    const onDeliveryRegistrationError = (e) =>{
        setcarregando(false)
        alert(e.response.data.data)
    }

    const lidarSalvarEndereco = () =>{
        setPopUpMapa(false)
        console.log(formData)
        console.log(  )
        setInputAutoComplete(formData["endereco"][0]["long_name"] +", "+ formData["numeroEndereco"] + ". "+ formData["endereco"][1]["long_name"]+". "+ formData["endereco"][2]["long_name"])
    }

    const registraNovaColeta =  useMutation(criaColeta, {
        onSuccess: (e) => {
            onDeliveryRegistrationSucess(e);
          
        },
        onError: (e) => {
            onDeliveryRegistrationError(e);
        }
    });

    const editaColeta =  useMutation(editaEntregaPorId, {
        onSuccess: (e) => {
            onDeliveryRegistrationSucess(e);
          
        },
        onError: (e) => {
            onDeliveryRegistrationError(e);
        }
    });
      

    return (
        trigger ?
            <PopUp trigger={trigger} setTrigger={setTrigger}>
                <div className='popUpConteudo'>
                    <div className='popUpConteudo-2'>
                        <div style={{ color:"#BCB0B0", fontSize:"12px", marginBottom:"15px"}}> {"(*) - Campos Obrigatórios"}</div>

                        {carregando == true &&
                            <div className='loader'>
                                <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={carregando} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
                            </div>
                        }

                        <div style={{display: 'flex'}}>
                            <div >
                                Endereço: *
                                <Autocompleta inputValor={inputAutoComplete} setaEndereco={(e) => handleMundancaEndereco(e, 'autoComplete')} style={{ display: popUpMapa ? 'none' : 'block', marginBottom:"15px", width:"400px"}} />
                            </div>

                            <LocationButton
                                trigger={popUpMapa}
                                setTrigger={setPopUpMapa}
                            />
                        </div>
                                            
                        {inputFields.map(({ label, name, placeholder, type, height }) => (
                            <Field
                                key={name}
                                label={label}
                                name={name}
                                value={formData[name]}
                                onChange={(e) => setFormData(prevState => ({ ...prevState, [name]: e }))}
                                placeholder={placeholder}
                                type={type}
                                width={"400px"}
                                height={height}
                            />
                        ))}

                        <div className='buttonRegistrar'>
                            <Button 
                                onClick={() => {lidarRegistroColeta()}}
                                width={"100px"}
                                color={"#ffffff"}
                                background={"rgb(66, 175, 60)"}
                                border={"rgb(66, 175, 60)"}
                                text={"Registrar"} 
                                margin={'auto'}
                            />

                        </div>
                    </div>
                    
                </div>
                <PopUpAlerta trigger={popUpAlerta} setTrigger={setPopUpAlerta}>
                    {
                        <div>
                            {conteudoPopUp}
                        </div>
                    }
                </PopUpAlerta>
                <PopUp trigger={popUpMapa} setTrigger={setPopUpMapa}>
                    {
                        <div>
                    <SelecionaEndereco
                        trigger={ popUpMapa }
                        setTrigger={setPopUpMapa}
                        setaEndereco={(e) => handleMundancaEndereco(e, 'selectEndereco')}
                        setaNumero={handleMundancaNumeroEndereco}
                        style={{ display: popUpMapa ? 'block' : 'none' , width: 'auto', overflow: 'hidden'}}
                    />

                    <div style={{display:"flex", justifyContent:"flex-end", marginTop:"10px", alignItems:"flex-end"}}>
                        <Button 
                            onClick={() => {lidarSalvarEndereco()}}
                            width={"100px"}
                            color={"#ffffff"}
                            background={"rgb(66, 175, 60)"}
                            border={"rgb(66, 175, 60)"}
                            text={"Salvar"} 
                            margin={'auto'}
                        />
                    </div>
                </div>
                    }
                </PopUp>
            </PopUp>
            : null
    );
};

export default PopUpSolicitaEntrega;
