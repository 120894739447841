import MapaAvancado from "../../componentes/maps/map/mapaAvancado";
import React, { useState, useRef } from "react";

import './mapa.scss'
export default function Places() {
  return <Mapa />;
}
  
export function Mapa() {
  return(
    <div className="main">
      <div className="tela-principal-mapa">
        <MapaAvancado />
      </div>
    </div>
  )
}